<template>
    <div>
      <!-- img图片 -->
        <div class="Slideshow">
          <el-carousel indicator-position="outside" height="5rem " >
            <el-carousel-item  v-for="(item, index) in getData" :key="index">
              <img  style="width: 100%;height: 100%;cursor: pointer;" :src="JSON.parse(item.accessory)[0].url" alt="">
            </el-carousel-item>
          </el-carousel>
          <button class="But" @click="jumpTo">我要报名参赛</button>
          <button class="Button" @click="apply">已报名？点我登录学习</button>
        </div>
    </div>
</template>
  
  <script>
  import $ from "jquery";
  import {getLogin} from "api/apis.js"

  export default {
    name: 'Home',
    components: {
    },
    data(){
      return {
        state:false,
        getData:"",
      }
    },
    methods:{
      sing(){
        let dataobj = new FormData();
				dataobj.append('label', "轮播"); //文件
				getLogin.getFile(dataobj).then(res => {
          console.log(res)
          console.log(JSON.parse(res.data))
          this.getData = JSON.parse(res.data)
				})
      },
      jumpTo() {
        this.state=true
        this.$emit('contest',this.state)
      },
      apply(){
        this.$router.push('/Train');
      },
    },
    mounted(){

    },
    created(){

    },
  }
  </script>
  
  <style scoped lang="scss">
.Slideshow /deep/ .el-carousel__arrow--left{
  // opacity: 0;
  position: absolute;
    top: 50%;
    width: 50px;
    height:50px;
    background: rgba(0,0,0,.5);
    border-radius: 24px;
    cursor: pointer;
    color: #fff;
}
.Slideshow /deep/ .el-carousel__arrow--right{
  // opacity: 0;
    position: absolute;
    width: 50px;
    height:50px;
    background: rgba(0,0,0,.5);
    border-radius: 24px;
    cursor: pointer;
    color: #fff;
}
.Slideshow /deep/ .el-carousel__container{
  height: auto;
  width: 100%;
}

  @import './slideshow.scss';
  </style>
  