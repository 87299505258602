<template>
  <!-- 赛事介绍 -->
  <div>
    <Footer></Footer>
    <div class="content">
      <!-- 轮播图图片 -->
      <img
        class="slideshow"
        @click="slideshow()"
        src="./Home_img/lunBo.jpg"
        alt=""
      />
      <div class="introduction_box">
        <img class="introduction" src="./Home_img/introduction.jpg" alt="" />
        <div>
          <p class="title">
            “挑战杯”全国大学生课外学术科技作品竞赛和中国大学生创业计划竞赛
          </p>
          <span class="text"
            >挑战杯是“挑战杯”全国大学生系列科技学术竞赛的简称，是由共青团中央、中国科协、教育部和全国学联共同主办的全国性的大学生课外学术实践竞赛，竞赛官方网站为
            <a href="https://www.tiaozhanbei.net/">www.tiaozhanbei.net</a>
            "挑战杯”竞赛在中国共有两个并列项目，一个是“挑战杯”中国大学生创业计划竞赛，另一个则是"挑战杯”全国大学生课外学术科技作品竞赛。这两个项目的全国竞赛交叉轮流开展，每个项目每两年举办一届。</span
          >
          <span class="text2"
            >"挑战杯”全国大学生课外学术科技作品竞赛
            (以下简称“挑战杯’竞赛”)是由共青团中央、中国科协、教育部、全国学联和地方政府共同主办，国内著名大学、新闻媒体联合发起的一项具有导向性、示范性和群众性的全国竞赛活动。自1989年首届竞赛举办以来，“挑战杯”竞赛始终坚持“崇尚科学、追求真知、勤奋学习、锐意创新、迎接挑战”的宗旨，在促进青年创新人才成长、深化高校素质教育、推动经济社会发展等方面发挥了积极作用......</span
          >
          <span class="text3" @click="more">查看更多>>></span>
        </div>
      </div>
      <!-- 挑战杯 -->
      <div class="challenge">
        <img @click="challenge" src="./Home_img/challenge.jpg" alt="" />
      </div>

      <!-- 往届回顾 -->
      <div class="introduction_box1">
        <div class="titleImg">
          <img class="headTitle" src="./Home_img/title.png" alt="" />
        </div>
        <div class="middleContent">
          <div class="leftContent">
            <img class="leftReview" src="./Home_img/leftReview.png" alt="" />
          </div>
          <div class="rightContent">
            <p class="unveilingTitle">
              揭榜5G+创新应用难题 助力中信科移动赋能垂直行业数智化转型
            </p>
            <span class="rightText">
              习近平总书记多次强调：“可以探索搞揭榜挂帅，把需要的关键核心技术项目张出榜来，英雄不论出处，谁有本事谁就揭榜。”为贯彻落实总书记的重要指示，引导和促进大学生踊跃投身5G技术创新应用赋能垂直行业数字化转型发展第一线，加速大学生科技创新成果向现实生产力转化，中信科移动通信技术股份有限公司提名的5G+创新应用仿真设计赛成功入选由共青团中央等举办的第十八届“挑战杯”全国大学生课外学术科技作品竞赛（以下简称“挑战杯”竞赛）“揭榜挂帅”专项赛。并于近日在贵州大学成功举办了该专项赛的擂台赛争夺。
            </span>
            <span class="text3" @click="more1">查看更多>>></span>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
  
  <script>
// @ is an alias to /src
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import Slideshow from "@/components/slideshow/slideshow.vue";
import $ from "jquery";

export default {
  name: "Home",
  components: {
    Footer,
    Bottom,
    Slideshow,
  },
  data() {
    return {};
  },
  methods: {
    slideshow() {
      // this.$message.error('大赛报名还未开启，敬请期待！');
      // this.$router.push("/Register");
    },
    more() {
      this.$router.push("/Brief");
    },
    more1() {
      this.$router.push({
        path: "/ViewDetail",
        query:{
          vType:'eleven'
        }
      });
    },
    challenge() {
      this.$router.push("/challenge");
    },
  },
};
</script>
<style scoped lang="scss">
@import "./Home.scss";
</style>